<template>
  <div class="addvote">
    <!-- 步骤条 -->
    <div class="steps">
      <Steps :current="current">
        <Step title="基本内容"></Step>
        <Step title="选项管理"></Step>
        <Step title="规则配置"></Step>
        <Step title="高级设置"></Step>
      </Steps>
    </div>
    <!-- 主体内容 -->
    <div class="container">
      <!-- 左侧菜单 -->
      <Row class="left-list">
        <Menu theme="light" :active-name="current" @on-select="selectMenu">
          <MenuItem :name="item.id" v-for="item in menuList" :key="item.id">{{
            item.name
          }}</MenuItem>
        </Menu>
      </Row>

      <!-- 右侧表单 -->
      <div class="right-content">
        <keep-alive>
          <component
              :is="componentId"
              :formValidate="formValidate"
              :ruleValidate="ruleValidate"
              :toggle1="toggle1"
              :textObj="textObj"
              :toggle-fn2="toggleFn2"
              @save="save"
              @changeData="changeData"
              @addOption="addOption"
              :ref="componentId"
              @delOption="delOption"
          ></component>
        </keep-alive>
      </div>
    </div>
     <Spin fix v-if="spinShow"></Spin>
  </div>
</template>

<script>
import baseInfo from '@/views/vote/childrens/changeBaseInfo'
import highSetting from "@/views/vote/childrens/changeHighSetting";
import optionManage from "@/views/vote/childrens/changeOptionManage";
import ruleConfig from "@/views/vote/childrens/changeRuleConfig";

export default {
  props: ['infoId',"menuCodeObj"],
  data() {
    const validateMobile = (rule, value, callback) => {
     if (value != '' && !/^1[3456789]\d{9}$/.test(value)) {
        callback("手机号格式不正确");
      } else {
        callback();
      }
    };
    return {
      //富文本预览
      toggle1: false, //
      textObj: {
        spinShow: false,
        signInUrl: '', //预览二维码url
      },
      showMobile: false,
      spinShow: false,
      //保存时表单验证
      validateArr: [
        {
          name: 'title',
          value: '信息名称不能为空！',
        },
        {
          name: 'source',
          value: '信息来源不能为空！',
        },
        {
          name: 'startTime',
          value: '请设置投票活动的开始日期',
        },
        {
          name: 'subStartTime',
          value: '请设置投票活动的开始时间',
        },
        {
          name: 'endTime',
          value: '请设置投票活动的结束日期',
        },
        {
          name: 'subEndTime',
          value: '请设置投票活动的结束时间',
        },
        {
          name: 'voteItemName',
          value: '请选择选手称呼',
        },
        {
          name: 'buttonName',
          value: '请选择投票按钮名称',
        },
        {
          name: 'buttonUnit',
          value: '请选择票数名称',
        },
        {
          name: "voteStartTime",
          value: "请输入每天起始时间",
        },
        {
          name: "voteEndTime",
          value: "请输入每天终止时间",
        },
         {
          name:'terminal',
          value:'请选择发布终端'
        },
      ],
      //当前右侧表单数据
      formValidate: {
        voteId: "",
        title: "",
        source: "", //信息来源
        subtype: "", //信息类型
        appointmentPhone: "", //联系电话（预约号码）
        startTime: "", //开始时间
        subStartTime: "",
        endTime: "", //结束时间
        subEndTime: "",
        provinceCode: 44, //省编码
        cityCode: "", //城市编码
        areaCode: "", //区县编码
        streetCode: "", //街道编码
        projectCodeList: "", //社区编码
        city: "", //修改时地址
        content: "", //富文本
        terminal:[],
        voteItemSeq: "2", //排序规则
        voteItemSeqList: [],  //排序规则列表

        voteItemRos: [
          {
            itemNum: 1, //序号
            title: "", //标题
            subtitle: "", //详情
            coverImg: "",
            converVideo: "",
            seq: 1, //序号
            modifier: parent.vue.loginInfo.userinfo.nickName,
          },
        ], //投票选项的List对象

        voteType: "1", //投票方式是单选还是多选
        voteTypeList: [],  //投票方式列表

        voteCycle: "1", //投票周期
        voteCycleList: [],  //投票周期列表

        voteMaxItem: 1, //可为同一选项投几票(单选的规则)
        voteMaxAccount: 1, //每个账号可以投几票(单选)
        selectMaxItem: 1, //最多选择几个选项(多选的规则)
        selectMinItem: 1, //最少选择几个选项(多选的规则)

        voteItemName: "", //选手称呼
        buttonName: "", //按钮名称
        buttonUnit: "", //票数单位
        voteItemNameList: [],  //选手称呼列表
        buttonNameList: [],  //按钮名称
        buttonUnitList: [],  //票数单位

        voteStartTime: "", //每天限制投票起始时间
        voteEndTime: "", //每天限制投票终止时间

        openDiscuss: "1", //	是否开启评论
        openDiscussList: [], //	是否开启评论列表
        reason: '',  //驳回原因
        realNameAuth: "1",  //实名认证
        realNameAuthList: [],
        recommend: '0',//是否推荐
        pushChannel :false, //小程序订阅推送

        modifier: parent.vue.loginInfo.userinfo.nickName, //修改人
        staffId: parent.vue.loginInfo.userinfo.id,
        status: 1,
        creatorId: parent.vue.loginInfo.userinfo.id,
      },
      ruleValidate: {
        title: [
          { required: true, message: "请输入信息名称", trigger: "blur" },
          { max: 50, message: "字数不能超过50字", trigger: "change" },
        ],
        source: [
          { required: true, message: "请输入信息来源", trigger: "blur" },
          { max: 30, message: "字数不能超过30字", trigger: "change" },
        ],
        subtype: [
          { required: true, message: "请选择信息类型", trigger: "blur" },
        ],
        startTime: [
          {
            required: true,
            type: "date",
            message: "请设置投票活动的开始时间",
            trigger: "blur",
          },
        ],
        subStartTime: [
          {
            required: true,
            type: "string",
            message: "请设置投票活动的开始时间",
            trigger: "change",
          },
        ],
        endTime: [
          {
            required: true,
            type: "date",
            message: "请设置投票活动的结束时间",
            trigger: "blur",
          },
        ],
        subEndTime: [
          {
            required: true,
            type: "string",
            message: "请设置投票活动的开始时间",
            trigger: "change",
          },
        ],
        cityCode: [
          {
            required: true,
            message: "请选择城市",
            trigger: "blur",
          },
        ],
        areaCode: [
          {
            required: true,
            message: "请选择区县",
            trigger: "blur",
          },
        ],
        streetCode: [
          {
            required: true,
            message: "请选择街道/乡镇",
            trigger: "blur",
          },
        ],
        projectCodeList: [
          {
            required: true,
            message: "请选择社区",
            trigger: "blur",
          },
        ],
        voteItemName: [
          {
            required: true,
            type: "string",
            message: "请选择选手称呼",
            trigger: "blur",
          },
        ],
        buttonName: [
          {
            required: true,
            message: "请选择投票按钮名称",
            trigger: "blur",
          },
        ],
        buttonUnit: [
          {
            required: true,
            message: "请选择票数名称",
            trigger: "blur",
          },
        ],
        openDiscuss: [
          {
            required: true,
            message: "请选择开启或者关闭评论",
            trigger: "blur",
          },
        ],
        terminal: [
          {
            required: true,
            message: "请选择发布终端",
            trigger: "blur",
          },
        ],
        voteType: [
          {
            required: true,
            message: "请选择投票方式",
            trigger: "blur",
          },
        ],
        voteStartTime: [
          {
            required: true,
            message: "请输入每天起始时间",
            trigger: "change",
          },
        ],
        voteEndTime: [
          {
            required: true,
            message: "请输入每天终止时间",
            trigger: "change",
          },
        ],
        voteCycle: [
          {
            required: true,
            message: "请选择投票周期",
            trigger: "blur",
          },
        ],
        selectMinItem: [
          {
            required: true,
            type: "number",
            message: "此项为必填",
            trigger: "change",
          },
        ],
        selectMaxItem: [
          {
            required: true,
            type: "number",
            message: "此项为必填",
            trigger: "change",
          },
        ],
        voteMaxAccount: [
          {
            required: true,
            type: "number",
            message: "此项为必填",
            trigger: "change",
          },
        ],
        voteMaxItem: [
          {
            required: true,
            type: "number",
            message: "此项为必填",
            trigger: "change",
          },
        ],
        realNameAuth: [
          {
            required: true,
            message: "此项为必填",
            trigger: "change",
          },
        ],
        recommend: [
          {
            required: true,
            message: "此项为必填",
            trigger: "change",
          },
        ],
      },
      //当前右侧组件ID
      componentId: "baseInfo",
      //步进器进度
      current: 0,
      //左侧菜单
      menuList: [
        {
          id: 0,
          name: "基本内容",
        },
        {
          id: 1,
          name: "选项管理",
        },
        {
          id: 2,
          name: "规则配置",
        },
        {
          id: 3,
          name: "高级设置",
        },
      ],
    };
  },
  created() {
    this.spinShow = true;
    //获取数据字典信息
    Promise.all([this.getDictionaries('VOTE_TYPE'),this.getDictionaries('VOTE_CYCLE'),this.getDictionaries('VOTE_ITEM_SEQ'),this.getDictionaries('VOTE_ITEM_NAME'),this.getDictionaries('VOTE_BUTTON_NAME'),this.getDictionaries('VOTE_BUTTON_UNIT'),this.getDictionaries('VOTE_DISCUSS'),  this.getDictionaries("VOTE_REAL_NAME_AUTH")]).then(res => {
      this.formValidate.voteTypeList = res[0].dataList;
      this.formValidate.voteCycleList = res[1].dataList;
      this.formValidate.voteItemSeqList = res[2].dataList;
      this.formValidate.voteItemNameList = res[3].dataList;
      this.formValidate.buttonNameList = res[4].dataList;
      this.formValidate.buttonUnitList = res[5].dataList;
      this.formValidate.openDiscussList = res[6].dataList;
       this.formValidate.realNameAuthList = res[7].dataList;
    })
      this.$get("/voteapi/api/pc/affiche/getAfficheInfo", {
        infoId: this.infoId,
      }).then((res) => {
        if (res.code == 200) {
          let startTime = new Date(res.data.startTime);
          let endTime = new Date(res.data.endTime);
          res.data.startTime = startTime;
          res.data.endTime = endTime;
          res.data.subStartTime = startTime;
          res.data.subEndTime = endTime;
          for (let k in res.data) {
            if (this.formValidate.hasOwnProperty(k)) {
              this.formValidate[k] = res.data[k];
            }
          }
           this.formValidate.pushChannel=this.formValidate.pushChannel==='4'?true:false
          this.spinShow = false;
           if(res.data.terminal && res.data.terminal != ''){
            this.formValidate['terminal'] = this.formValidate['terminal'].split(',')
          }
        }
      });
      this.$get("/voteapi/api/pc/affiche/getAfficheInfoManager", {
        infoId: this.infoId,
      }).then((res) => {
        if (res.code == 200) {
          this.formValidate.city = res.dataList[0];
        }
      });
      this.$get("/voteapi/api/pc/componentVote/getVoteInfo", {
         businessType: this.menuCodeObj.businessType,
            functionType: this.menuCodeObj.functionType,
        businessCode: this.infoId,
      }).then((res) => {
        if (res.code == 200) {
          for (let k in res.data) {
            if (this.formValidate.hasOwnProperty(k)) {
              this.formValidate[k] = res.data[k];
            }
          }
          this.formValidate["voteItemRos"] = res.data.voteItemVos;
          this.formValidate['voteId'] = res.data.voteId;
         
        }
      });
  },
  watch: {
    current(newVal) {
      this.$emit('comSelectMenu',newVal)
    }
  },
  methods: {
    getDictionaries(type) {  //获取数据字典数据
       return this.$get('/voteapi/api/common/sys/findDictByDictType',{
        dictType: type
      })
    },
    //删除选项管理中的选项
    delOption(index) {
      this.formValidate.voteItemRos.splice(index, 1);
    },
    //选项管理添加选项
    addOption() {
      this.formValidate.voteItemRos.push({
        itemNum: this.formValidate.voteItemRos.length + 1, //序号
        title: "", //标题
        subtitle: "", //详情
        coverImg: "",
        converVideo: "",
        seq: this.formValidate.voteItemRos.length + 1, //序号
        modifier: parent.vue.loginInfo.userinfo.nickName,
      });
    },
    //改变table数据
    changeData(arr) {
      this.formValidate.voteItemRos[arr.index][arr.name] = arr.value;
    },
    // 对时间数据进行转化
    toggleTime(time, subtime) {
      if (time && subtime) {
        let startTime = new Date(time);
        let startYear = startTime.getFullYear();
        let startMonth =
          startTime.getMonth() + 1 < 10
            ? "0" + (startTime.getMonth() + 1)
            : startTime.getMonth() + 1;
        let startDay =
          startTime.getDate() < 10
            ? "0" + startTime.getDate()
            : startTime.getDate();
        return startYear + "-" + startMonth + "-" + startDay + " " + subtime;
      }
    },
     //保存时验证表单必填
    validate() {
      for(let k in this.validateArr) {
        if(k == this.validateArr.length -1 && this.formValidate[this.validateArr[k].name] !== "") {
          return true;
        }
        if (this.formValidate[this.validateArr[k].name] == "") {
          this.$Message["error"]({
            background: true,
            content: this.validateArr[k].value,
          });
          return false;
        }
      }
    },
    toggleFn2() {
      this.toggle1 = false
    },
    contentFn() {   //富文本预览
      console.log('执行了2')
      var v_Obj = document.getElementById("ueditor").contentWindow; //获取iframe对象
      this.formValidate.content = v_Obj.getContent(); //获取富文本内容
      console.log(this.formValidate.content, '内容')

      if (!this.formValidate.content) {
        this.$Message.warning({
          background: true,
          content: '暂没内容，点击编辑吧！',
          duration: 2
        });
        return
      }
      this.$Message.loading({
        background: true,
        content: '正在加载，请稍等...'
      });


      let params = {
        content: this.formValidate.content
      }
      this.$post(
          "/datamsg/api/pc/wxqr/createWxAppletQr",
          {
            type: "RICH_TEXT_PREVIEW_PAGE",
            value: JSON.stringify({
              path: "pages/welcome/welcome",
              redirectPath:
                  "/indexPages/previewRichText/index",
              params: {
                ...params,
              }
            })
          },
          {"Content-Type": "application/json"}
      ).then(res => {
        console.log(res, 'res')
        if (res.code == 200 && res.data) {
          this.textObj.signInUrl = res.data.codeUrl;
          this.toggle1 = true
          // this.spinShow=true
          this.$Message.destroy()
        } else {
          this.$Message.destroy()
          this.$Message.error({
            background: true,
            content: res.desc
          });
        }
      });
    },
    //保存
    save() {
      if(this.formValidate.pushChannel == true){
        this.$Modal.confirm({
          title: "退出提示",
          content: "<p>当前发布包括推送，是否继续发布？</p>",
          onOk: () => {
            this.addPush()
          }
        })
      }else{
          this.addPush()
      }
     
    },
    addPush(){
      if(this.formValidate.voteType == '1' && this.formValidate.voteMaxAccount && this.formValidate.voteMaxItem && (this.formValidate.voteMaxAccount < this.formValidate.voteMaxItem)){
          this.$Message.error({
            content:'规则配置--可为同一选手投票数不能大于每个用户可以投票数',
            background:true
          })
          return
      }
      this.formValidate.pushChannel = this.formValidate.pushChannel === false ? '' : '4'
      if (this.validate()) {
        this.formValidate.startTime = this.toggleTime(
            this.formValidate.startTime,
            this.formValidate.subStartTime
        );
        this.formValidate.endTime = this.toggleTime(
            this.formValidate.endTime,
            this.formValidate.subEndTime
      );
      this.formValidate.voteItemRos.map((item, index, arr) => {
        item.itemNum = item.seq;
      });
      if(this.componentId == 'baseInfo') {
         var v_Obj=document.getElementById("ueditor").contentWindow;//获取iframe对象
        this.formValidate.content = v_Obj.getContent();//获取富文本内容
      }
      let data = {
        componentRo: {
          realNameAuth: this.formValidate.realNameAuth,
          backgroundImg: "",
          backgroundMusic: "",
          voteId: this.formValidate.voteId,
          buttonName: this.formValidate.buttonName,
          buttonUnit: this.formValidate.buttonUnit,
          color: "",
          componentType: "",
          flotage: "",
          modifier: this.formValidate.modifier,
          openDiscuss: this.formValidate.openDiscuss,
          selectMaxItem: this.formValidate.selectMaxItem,
          selectMinItem: this.formValidate.selectMinItem,
          showStat: "",
          voteCycle: this.formValidate.voteCycle,
          voteEndTime: this.formValidate.voteEndTime,
          voteItemName: this.formValidate.voteItemName,
          voteItemRos: this.formValidate.voteItemRos,
          voteItemSeq: this.formValidate.voteItemSeq,
          voteMaxAccount: this.formValidate.voteMaxAccount,
          voteMaxItem: this.formValidate.voteMaxItem,
          voteStartTime: this.formValidate.voteStartTime,
          voteType: this.formValidate.voteType,
        },
        updateAfficheRo: {
          address: "",
          afficheType: "",
          appointmentPhone: this.formValidate.appointmentPhone,
          averagePrice: "",
          businessTime: "",
          carousel: 0,
          columnCode: this.menuCodeObj.columnCode,
          componentType: "",
          content: this.formValidate.content,
          creatorId: this.formValidate.creatorId,
          endTime: this.formValidate.endTime,
          id: this.infoId,
          introduction: "",
          label: "",
          latitude: "",
          linkUrl: "",
          liveBroadcastRoomNum: "",
          longitude: "",
          operType: "UPDATE",
          peopleNum: 0,
          photo: "",
          priority: "",
          reason: "",
          shopType: "",
          source: this.formValidate.source,
          staffId: this.formValidate.staffId,
          startTime: this.formValidate.startTime,
          status: '1',
          subtype: this.formValidate.subtype,
          terminal: this.formValidate.terminal.join(','),
          title: this.formValidate.title,
          recommend: this.formValidate.recommend, //是否推荐
          pushChannel: this.formValidate.pushChannel, //小程序订阅推送
        },
      };
        this.$post("/voteapi/api/pc/affiche/updateAfficheVote", data,{"Content-Type": "application/json"}).then(
          (res) => {
            if (res.code == 200) {
              this.$Message["success"]({
                background: true,
                content: "修改成功",
              });
              this.$emit('pageChange',{
                page: 1,
                pageSize: 10
            })
             //重新获取信息状态的数量
            this.$emit("messageStatusCount");
              this.$emit('addChangeModalFn',false);
            } else {
              this.$Message["error"]({
                background: true,
                content: res.desc,
              });
            }
          }
        );
      }
    },
    //步进器上下一步
    nextStep(value) {
      this.selectMenu(this.current + value);
    },
    selectMenu(name) {
       if(this.componentId == 'baseInfo') {
         var v_Obj=document.getElementById("ueditor").contentWindow;//获取iframe对象
        this.formValidate.content = v_Obj.getContent();//获取富文本内容
      }
      this.current = name;
      switch (name) {
        case 0:
          this.componentId = "baseInfo";
          break;
        case 1:
          this.componentId = "optionManage";
          break;
        case 2:
          this.componentId = "ruleConfig";
          break;
        default:
          this.componentId = "highSetting";
      }
    },
  },
  components: {
    baseInfo,
    highSetting,
    optionManage,
    ruleConfig,
  },
};
</script>
<style scoped lang='less'>
.addvote {
  background: #fcfcfc;
  border: 1px solid transparent;
  text-align: left;

  .steps {
    height: 80px;
    margin: 20px 50px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .ivu-steps {
      width: 80%;
    }
    .ivu-steps-status-process,
    .ivu-steps-status-finish {
      /deep/.ivu-steps-head-inner {
        border-color: #12b26d;
        background-color: #12b26d;
      }
    }
    /deep/.ivu-steps-icon {
      color: #fff;
    }
    /deep/.ivu-steps-item.ivu-steps-status-finish .ivu-steps-tail > i:after {
      background: #12b26d;
    }
    /deep/.ivu-steps-status-error .ivu-steps-icon {
      color: #ed4014;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin: 0 50px;
    min-height: calc(100vh - 243px);
    .ivu-menu-item {
      color: #303133;
      background: #fff;
    }
    .ivu-menu-item:hover {
      color: #409eff;
    }
    .ivu-menu-item-selected.ivu-menu-item-active {
      background: #fff;
      color: #409eff;
    }
    .left-list {
      height: 100%;
      width: 220px;
    }
    /deep/.ivu-menu {
      text-align: center;
      width: 220px !important;
      background: #fcfcfc;
      li {
        background: #fcfcfc;
      }
      li,
      .ivu-menu-item-selected:hover,
      li:hover {
        color: #12b26d;
      }
      .ivu-menu-item-selected {
        color: #12b26d;
      }
    }
    .ivu-menu-vertical.ivu-menu-light:after {
      width: 2px;
    }
    .ivu-menu-item-active:not(.ivu-menu-submenu):after {
      background: #12b26d;
    }
    .right-content {
      flex: 1;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      background: #fff;
      height: calc(100vh - 243px);
      padding: 20px;
    }
  }
}
</style>
