import { render, staticRenderFns } from "./changeVote.vue?vue&type=template&id=62c47254&scoped=true&"
import script from "./changeVote.vue?vue&type=script&lang=js&"
export * from "./changeVote.vue?vue&type=script&lang=js&"
import style0 from "./changeVote.vue?vue&type=style&index=0&id=62c47254&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c47254",
  null
  
)

export default component.exports