<template>
  <div class="baseInto" ref="info">
    <!-- 右边表单区块 -->
    <Form
      ref="formValidate"
      :model="formValidate"
      :rules="ruleValidate"
      :label-width="100"
      :label-colon="true"
    >
      <FormItem label="信息名称" prop="title">
        <Input
        :maxlength="50"
          v-model.trim="formValidate.title"
          placeholder="请输入信息名称，限50字"
        />
      </FormItem>
      <FormItem label="信息来源" prop="source">
        <Input
        :maxlength="30"
          v-model.trim="formValidate.source"
          placeholder="请输入信息名称，限30字"
        />
      </FormItem>
      <!-- prop="subtype"  这是信息类型校验，暂无数据 -->
      <FormItem label="信息类型" class="form-item">
        <Select v-model="formValidate.subtype" filterable>
          <Option
            v-for="(item,index) in subtypeList"
            :value="item.value"
            :key="index"
            >{{ item.label }}</Option
          >
        </Select>
      </FormItem>
      <FormItem label="联系电话" class="form-item" prop="appointmentPhone">
        <Input
        :maxlength="12"
          v-model.trim="formValidate.appointmentPhone"
          placeholder="请输入联系电话"
        />
      </FormItem>
      <FormItem label="公布时间" prop="startTime" class="form-item">
        <FormItem prop="startTime" class="form-item" style="width: 48%">
          <DatePicker
            type="date"
            placeholder="请设置日期"
            v-model="formValidate.startTime"
          ></DatePicker>
        </FormItem>
        <span class="time-interval">-</span>
        <FormItem prop="subStartTime" class="form-item" style="width: 48%">
          <TimePicker
            type="time"
            placeholder="请设置时间"
            v-model="formValidate.subStartTime"
          ></TimePicker>
        </FormItem>
      </FormItem>
      <FormItem label="截至时间" prop="endTime" class="form-item">
        <FormItem prop="endTime" class="form-item" style="width: 48%">
          <DatePicker
            type="date"
            placeholder="请设置日期"
            v-model="formValidate.endTime"
          ></DatePicker>
        </FormItem>
        <span class="time-interval">-</span>
        <FormItem prop="subEndTime" class="form-item" style="width: 48%">
          <TimePicker
            type="time"
            placeholder="请设置时间"
            v-model="formValidate.subEndTime"
          ></TimePicker>
        </FormItem>
      </FormItem>
      <FormItem label="发布范围" class="form-item" style="width: 100%">
         <Input
         :maxlength="20"
          v-model.trim="formValidate.city"
          disabled
        />
      </FormItem>
      <FormItem label="发布终端" class="form-item" style="width: 100%" prop="terminal">
          <CheckboxGroup v-model="formValidate.terminal" @on-change="allSelect">
            <Checkbox label="0">全部</Checkbox>
            <Checkbox label="1">TV</Checkbox>
            <Checkbox label="3">APP</Checkbox>
            <Checkbox label="4">小程序</Checkbox>
          </CheckboxGroup>
        </FormItem>
        <!-- <iframe name="ueditor" id="ueditor" src="/ueditor/index.html?titlename=详情描述"
          style="width:100%;height:500px;border:0px;" @load="loadUeditor"></iframe> -->
          <iframe
                name="ueditor"
                id="ueditor"
                src="/mobileeditor/#/index?titlename=详情描述"
                style="width: 100%; height: 500px; border: 0px"
                @load="loadUeditor"
              ></iframe>
    </Form>

    <!--   预览富文本二维码 -->
    <Mymodel
        :fullscreen="false"
        :value="toggle1"
        :toggle="true"
        :textObj="textObj"
        title="文本预览"
        :toggle-fn2="toggleFn2"
    >
    </Mymodel>
  </div>
</template>

<script>
import Mymodel from "@/views/activity/Mymodel";

export default {
  props: ["formValidate", "ruleValidate",'toggle1','textObj','toggleFn2'],
  data() {
    return {
      subtypeList: [], //信息类型
      projectList: [],
      terminalList:[]
    };
  },
  created() {
    sessionStorage.setItem('NODEENV',process.env.NODE_ENV)
     //获取信息类型
    this.$get('/voteapi/api/pc/menu/getMenuParam',{
        columnCode: this.$core.getUrlParam('menuId') || 200,
         orgCode: parent.vue.loginInfo.userinfo.orgCode,
        // columnCode: '1701'
      }).then(res => {
        this.subtypeList = res;
      })
  },

  methods: {
    // 发布终端全选
    allSelect(arr) {
      if (this.terminalList.length == 4 && arr.indexOf("0") == -1) {
        this.formValidate.terminal = [];
      } else if (this.terminalList.length == 4 && arr.indexOf("0") > -1) {
        arr.splice(arr.indexOf("0"), 1);
        this.formValidate.terminal = arr;
      } else if (arr.length == 3 && arr.indexOf("0") == -1) {
        this.formValidate.terminal = ["0", "1", "3", "4"];
      } else if (arr.indexOf("0") > -1 && arr.length < 4) {
        this.formValidate.terminal = ["0", "1", "3", "4"];
      }
      this.terminalList = JSON.parse(JSON.stringify(this.formValidate.terminal));
    },
    loadUeditor() {
      if(this.formValidate&&this.formValidate.voteId!=null&&this.formValidate.voteId!=""){
        var v_Obj=document.getElementById("ueditor").contentWindow;//获取iframe对象
       v_Obj.setContent(this.formValidate.content);//写入编辑器富文本内容
      }else{
       setTimeout(this.loadUeditor,1000);
      }
    },
  },
  components:{
    Mymodel
  }
};
</script>

<style scoped lang='less'>
.baseInto {
  height: 100%;
  // overflow: auto;
}
.ivu-form {
  .form-item {
    width: 50%;
    display: inline-block;
  }
  /deep/.ivu-select-dropdown {
    z-index: 99999;
  }
  .ivu-date-picker {
    width: 100%;
  }
  .time-interval {
    width: 1%;
    margin: 0 1%;
  }
  /deep/.edui-editor {
    z-index: 1 !important;
  }
}
</style>
